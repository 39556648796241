.columnA {
  position: relative;
  padding: 10px 0px;
}
.columnB {
  position: relative;
  padding: 10px 20px;
}
.poweredBy {
  display: inline;
  padding: 5px 0px;
}
.phoneNumberCardDiv {
  padding: 30px 40px;
  border: 1px solid #fff;
  border-radius: 3px;
  text-align: center;
}
.col2div {
  padding-top: 30px;
  text-align: center;
}
.complexFooterRowOne {
  padding-bottom: 60px;
  color: #fff;
}
.complexFooterRowTwo {
  position: relative;
  padding-top: 1rem;
}
.footerImgOne {
  max-height: 40px;
}
.footerImgTwo {
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  display: block;
  top: -30px;
  max-height: 80px;
  text-align: center;
}
.footerColOne {
  width: auto;
  padding: 5px 0px;
}
.footerColTwo {
  line-height: 1.6em;
  text-align: center;
  margin: 40px 0;
}
.footerAnchorOne {
  color: #fff;
}
.footerAnchorTwo {
  display: block;
  font-weight: 700;
  font-size: 1.3em;
  padding: 5px 0;
}
.footerList {
  margin: auto;
  display: block;
}
.footerH6 {
  display: inline;
  padding: 5px 0px;
}
.footerInfo {
  margin: 0;
}
.footerParagraph {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 1px;
}
.footerNavLink {
  color: #fff;
  padding: 0 10px;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: 600;
}
